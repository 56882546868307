import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "./BaseCard.js";
import styles from "../assets/jss/baseCardstyle.js";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom"
import FadeInModal from "../components/FadeInModal";
import D3LinearChart from "./D3LinearChart.js";
const useStyles = makeStyles(styles);
export default function GraphCard(props) {
    const { graphData, justDetailButton, institution } = props;
    const classes = useStyles();
    const [modalState, setModalState] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const closeModal = () => { //function to pass through props to FadeInModal
        setModalState(false);
    };
    return (
        <BaseCard >
            <div className={classes.graphCardHeader}>
                <Link to={"/viewChart/" + graphData.key} style={{ textDecoration: 'none' }}>
                    <Typography variant="h2" className={classes.emphasizedHeader}>{graphData.title}</Typography>
                </Link>
                {
                    justDetailButton && (
                        <div className={classes.graphOptionsMenuContainer}>
                            <div className={classes.graphOptionsMenuButton} onClick={() => { setModalTitle(graphData.title); setModalState(true); }}>
                                <div className={classes.graphOptionsMenuIcon}>
                                    <i class="fas fa-info fa-1x"></i>
                                </div>
                                <Typography variant="subtitle2">Metodología</Typography>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className={classes.graphCardContiner}>
                <Link to={"/viewChart/" + graphData.key} style={{ textDecoration: 'none' }}>
                    {
                        <div>
                            <div>
                                <D3LinearChart width={600} height={355} source={graphData.data} />
                            </div>
                        </div>
                    }
                </Link>
            </div>

            {
                justDetailButton && (
                    <FadeInModal open={modalState} handleClose={closeModal} modalTitle={
                        graphData.title +
                        (
                            (props.variant && props.variant == "global") ? "" : (" del "+ institution.name)
                        )
                    }
                    >

                        <Typography variant="caption">
                            <p>
                                <div dangerouslySetInnerHTML={{ __html: graphData.methodology }} />
                            </p>
                        </Typography>
                    </FadeInModal>
                )
            }
        </BaseCard>
    );
}