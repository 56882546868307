import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
//import logo4 from "assets/img/lasalleLogo.svg"; logo to be displayed
import styles from "../assets/jss/headerstyle.js";
const useStyles = makeStyles(styles);
export default function SearchHeader(props) {
    //const {inHeader} =props;
    const classes = useStyles();
    const theme = useTheme();
    const [searchValue, setSearchValue] = React.useState('');
    const handleChange = (newValue) => {
        setSearchValue(newValue);
    };
    return (
        <AppBar position='static' className={classes.appBar}  >
            <div className={classes.wrapper}>
                <Toolbar className={classes.toolBar}>
                    <div className={classes.searchBoxContainer}>
                        <div className={classes.searchBox}>
                            <FormControl fullWidth className={classes.margin} style={{background: theme.palette.secondary.lighter}}>

                                <OutlinedInput
                                    value={searchValue}
                                    inputProps={{ style: {background: theme.palette.secondary.lighter, padding: '10px 10px' } }}
                                    onChange={(e) => handleChange(e.target.value)}
                                    endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}

                                />
                            </FormControl>
                        </div>
                    </div>
                </Toolbar>
            </div>
        </AppBar>
    );
}