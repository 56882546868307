import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/institutionScreenstyle.js";
import { Grid, Typography } from "@material-ui/core";
import BaseScreen from "./BaseScreen";
import { useLocation ,useParams} from "react-router-dom";
import axios from "axios";
import ApiURLs from "../config/ApiURLs.js";
import GraphCard from "../components/GraphCard.js";
//import AppContext from '../state/AppContext';
import YoutubeEmbed from "../components/YoutubeEmbed";
const useStyles = makeStyles(styles);


/*function useQuery() {
    return new URLSearchParams(useLocation().search);
}*/



export default function InstitutionScreen(props) {
    //const appContext = React.useContext(AppContext);
    const classes = useStyles();
    const location = useLocation();
    //const queryParams = useQuery();
    let {id} = useParams();//queryParams.get('id');
    //const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] /*remove fakeData once backend is ready*/ });
    const [thisInstitution, setThisInstitution] = React.useState({ isLoading: true, data: {'name':'...'} /*remove fakeData once backend is ready*/ });
   

    React.useEffect(() => {
        axios.get(ApiURLs.getURL(ApiURLs.baseURL + 'website/institution?id=' + id,'json')).then((data) => {  
             setThisInstitution({isLoading:false,data:data.data});
        });
    }, [location,id]);

    /*const renderInstitutionData = () => {
        return (
            dataRep.data.data.map((element, index) => {
               {
                    return (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                            <GraphCard graphData={element} justDetailButton />
                        </Grid>
                    )
                }
            })
        )
    };*/
    return (
        
        //, { display: "Instituciones", href: '/institutions' }, { display: dataRep.data.institutionName, href: '/institution?id=' + dataRep.data.institutionId 
        <BaseScreen primaryHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }]}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                <div>
                    <Typography variant="h1" className={classes.title}> {
                               (!thisInstitution.isLoading)?thisInstitution.data.name:<i class="fas fa-spinner"></i> //id
                               
                    }</Typography>
                    <div className={classes.subtitleContainer}>
                        <Typography variant="body1" className={classes.longDescription}>{ 
                            (!thisInstitution.isLoading)?thisInstitution.data.description:<i class="fas fa-spinner"></i>
                        }</Typography>
                    </div>
                </div>

                </Grid>

                
                <Grid container xs={12} sm={12} md={8}>
                    {
                        (!thisInstitution.isLoading)?thisInstitution.data.charts.map((element, index) => {
                            // if (element.type == 1) {
                            
                                return (
                                    <Grid item xs={12} sm={12} md={6} key={index}>
                                        <GraphCard graphData={element} justDetailButton institution={thisInstitution.data} />
                                    </Grid>
                                )
                            // }
                        }):"No hay datos que mostrar aún."
                        

                    
                    }
                </Grid>
                <Grid container xs={12} sm={12} md={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div className={classes.rightSideTextContainer}> 
                            {/*<Typography variant="subtitle2">*/}
                             { (thisInstitution.data.video)?
                                <YoutubeEmbed embedId={thisInstitution.data.video} />:''
                            }
                            {/*</Typography>*/}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </BaseScreen>
    )
}