import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/institutionsScreenstyle.js";
import BaseScreen from "./BaseScreen";
import {Grid,  Typography } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import AppContext from '../state/AppContext';
const useStyles = makeStyles(styles);



export default function InstitutionsScreen(props){
    const appContext = React.useContext(AppContext);
    const classes = useStyles();

    const renderInstitutions = () => {
        if (!appContext.isLoading) {
            return (
                appContext.institutions.map((element, index) => {
                    return (

                        <Link to={"/institution/" + element.id} className={classes.navigationMenuItemLink} key={index}>
                            <ListItem button={true} className={classes.institutionItem}>
                                <ListItemText primary={element.name} />
                            </ListItem>
                             {/*<Link to={"/institution?id=" + element.id} className={classes.navigationMenuItemLink} key={index}>
                            <StyledMenuItem>
                                <ListItemText primary={<Typography variant="caption" >{element.name}</Typography>} />
                            </StyledMenuItem>
                        </Link>*/}
                        </Link>


                       
                    )
                })
            );
       }
    }


    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{display:"Inicio",href:"/index"},{display:"Instituciones", href:'/institutions'}]}>
             <div>
                <Typography variant="h1" className={classes.title}>Instituciones</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Insitutuciones que conforman el Sistema Judicial en Guatemala.</Typography>
                </div>
            </div>
            <Grid container>
                <Grid container xs={12} sm={12} md={8}>
                   <div className={classes.listCard}>
                        <List dense={false} className={classes.institutionList}>
                        
                            {/*<Link to="/institution?id=1">
                            <ListItem button={true} className={classes.institutionItem}>
                                <ListItemText primary="Organismo Judicial" />
                            </ListItem>
                            </Link>
                            <ListItem button={true} className={classes.institutionItem}>
                                <ListItemText primary="Ministerio Público" />
                            </ListItem>
                            <ListItem button={true} className={classes.institutionItem}>
                                <ListItemText primary="Instituto Nacional de Ciencias Forenses" />
                            </ListItem>
                            <ListItem button={true}>
                                <ListItemText primary="Instituto de la Defensoría Pública Penal"  className={classes.institutionItem}  />
    </ListItem>*/}
                            {renderInstitutions()}
                       
                        </List>
                    </div>
                </Grid>
                <Grid container xs={12} sm={12} md={4}>
                    <Grid item xs={12} sm={12} md={12}>
                        
                    </Grid>
                </Grid>
            </Grid>
        </BaseScreen>
    )
}