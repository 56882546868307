import React from 'react';
import styles from "../assets/jss/headerstyle.js";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppContext from "../state/AppContext.js";
import { Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';


const useStyles = makeStyles(styles);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function SimpleHeaderMenu(props) { //will be used to display institutions, and the main difference between this and HeaderMenu.js is the with it occupies
    const classes = useStyles();
    const { rootComponent, options, prefix } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const appContext = React.useContext(AppContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderOptions = () => {

        return (
            options.map((element, index) => {
                return (
                    <Link key={index} to={ prefix?prefix+element.id:element.id} className={classes.navigationMenuItemLink}  onClick={handleClose}>
                        <StyledMenuItem >
                            <ListItemText primary={<Typography variant="caption" >{element.name}</Typography>} />
                        </StyledMenuItem>
                    </Link>
                )
            }
            )
        )
    };

    return (
        <div>
            <div onClick={handleClick}>
                {rootComponent}
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {renderOptions()}
            </StyledMenu>
        </div>
    )
}
