import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as d3 from "d3";
import { Typography } from "@material-ui/core";
import styles from "../assets/jss/linearChartstyle.js";
const useStyles = makeStyles(styles);


const margin = { top: 40, right: 80, bottom: 60, left: 50 };
/*var width = 600,
    height = 380,*/
var color = "#1f95dd";

// width = 600,
// height = 400,
// color = "#1f95dd";
const lineColors = [
    "#1f95dd",
    "#DD671F",
    "#1fdd67",
    "#671fdd",
    ///10/09/2021 - Only four colors got added, but you can add more colors if needed
]

const requiredSizeForXAxis = 10,
    requiredSizeForYAxis = 30;

export default function D3LinearChart(props) {
    let d3format=d3.format("0");
    let isPercentage = false;
    const classes = useStyles();
    const theme = useTheme();
    const { source, isPreview, width, height, requiresPercentage, requiresLegends } = props;
    const [activeIndex, setActiveIndex] = React.useState(null);
    const [dataRep, setDataRep] = React.useState({isLoading:true, data:[], scaledBy:undefined});
    var scaleOptions = [{ base: 1000, label: 'Mil', xOffset: 13 }, { base: 1000000, label: 'Mill', xOffset: 10 }]; //always 

    if(undefined!==source[0] && undefined!==source[0].type && source[0].type=="percentage") {
        isPercentage=true;
        d3format=d3.format(".0%");
    }
    React.useEffect(() => {
        ///here goes the code to fetch csv data or xml, but since this is a sample, we will just receive it from component props.
        // console.log("The source", source)
        //console.log('sourc X',source);        
        if (undefined != source) {
            var fullyTreatedData = sanitizeYearInputs(source);
            fullyTreatedData = scaleData(fullyTreatedData);
            console.log('fullyTreatedData', fullyTreatedData);
            setDataRep({ isLoading: false, data: fullyTreatedData.data, scaledBy: fullyTreatedData.scaledBy });
        }
    }, [source]);

    const sanitizeYearInputs = (sourceArray) => {
        ///Method used to make sure year inputs are as expected because we cannot rely on final users 
        return sourceArray.map((element, index) => {
            var elementToFix = element;
            var d3Parser = d3.timeParse("%Y");
            elementToFix['year'] = d3Parser(elementToFix.year);
            return elementToFix;
        });
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const groupData = (sourceArray) => {
        return sourceArray.reduce((r, a) => {
            r[a.name] = [...r[a.name] || [], a];
            return r;
        }, {});
    }

    const groupedData = groupData(dataRep.data);
    //console.log("GroupedData", groupedData);
    var groupedDataKeys = Object.keys(groupedData);
    const yMinValue = d3.min(dataRep.data, (d) => { 
        console.log('finding min',d);
        if (typeof (d.data) == "string"){
            if (undefined !== d.type) 
                if (d.type=="percentage") d.data=parseFloat(d.data);
                else d.data=parseInt(d.data);
            else d.data=parseInt(d.data);
        }
        return (d.data);}
    ),
        yMaxValue = d3.max(dataRep.data, (d) => { return (typeof (d.data) == "string") ? (undefined==d.type && d.type=="percentage")?parseFloat(d.data):parseInt(d.data) : d.data });

    const scaleData = (unScaledData) => {
        //var yMeanValue = d3.mean(unScaledData, (d) => d.data);
        console.log('unscaledData',unScaledData);
        var yMeanValue = unScaledData.reduce((prev,current)=> {return {data:prev.data+Math.abs(current.data)}},{data:0}).data/unScaledData.length;
        console.log('yMeanValue',yMeanValue, 'y la media es ', unScaledData.length);
        for (var i = scaleOptions.length - 1; i >= 0; i--) {
            var currentScale = scaleOptions[i];
            var scaleFound = undefined;
            if (yMeanValue > currentScale.base) {
                scaleFound = currentScale;
                break;
            }
        }
        var scaledArray = unScaledData;
        if (scaleFound) {
            scaledArray = scaledArray.map((element, index) => {
                var elementToFix = element;
                elementToFix['unscaledData'] = element.data;
                elementToFix['data'] = +(elementToFix.data / scaleFound.base).toFixed(1);
                return elementToFix;
            });
        }
        return { data: scaledArray, scaledBy: scaleFound };
    }


    
    const getX = d3
        .scaleTime()
        .domain(
            ((dataRep.data.length)&&(undefined!=dataRep.data[0].variant)&&(dataRep.data[0].variant=="trimester"))?
                [new Date("2021-01-01"), new Date("2021-08-10")]
                /*d3.extent(dataRep.data, (d) => {
                    console.log("d:::::",new Date((1900+d.year.getYear()).toString()+"-"+
                    (
                        ( parseInt(d.trimester)*3 <10 )? "0":""
                    
                    )+ (parseInt(d.trimester)*3).toString()
                +"-15"));
                    return new Date(((1900+d.year.getYear()).toString()+"-"+
                    (
                        ( parseInt(d.trimester)*3 <10 )? "0":""
                    
                    )+ (parseInt(d.trimester)*3).toString()
                +"-15"));
                 })*/
            :
            d3.extent(dataRep.data, (d) => {
               return new Date(d.year);
            })
        
        )
        .range([requiredSizeForYAxis, width - requiredSizeForYAxis]);

    const getY = d3
        .scaleLinear()
        //.domain([yMinValue - 1, yMaxValue + 2])
        .domain([yMinValue < 0 ? yMinValue*1.10:0, yMaxValue * 1.10])
        //.range([height, 0]);
        .range([height - requiredSizeForXAxis, 0]);

    const getXAxis = (ref) => {
        let format="%Y";
        if (!dataRep.isLoading)
        if (dataRep.data.length>0)
        if (undefined!=dataRep.data[0].variant){
            if (dataRep.data[0].variant=="trimester")
                format+="-%b";
        }
        const xAxis = d3.axisBottom(getX).tickSizeOuter(0)
        d3.select(ref).call(xAxis.tickFormat(d3.timeFormat(format)));
    };

    const getYAxis = (ref) => {
        const yAxis = d3.axisLeft(getY).tickFormat(d3format).tickSize(-width + 2 * requiredSizeForYAxis-5).tickPadding(7).tickSizeOuter(0);
        d3.select(ref).call(yAxis);
    };

    const linePath = (key) => d3    
        .line()
        .x((d) => getX(new Date(
            ((undefined!=d.variant)&&(d.variant=="trimester"))?
                 (
                    new Date((1900+d.year.getYear())+"-"+ 
                        ((parseInt(d.trimester)*3<10)?"0":"")
                        +
                        (
                            (parseInt(d.trimester)*3).toString()
                        )
                        +"-15"
                    )
                )
            :
                new Date(d.year)

        )))
        .y((d) => getY(d.data))
        .curve(d3.curveMonotoneX)(groupedData[key])
    
    //if ((!dataRep.isloading)&&(dataRep.data.length>0))
        
    const areaPath = d3
        .area()
        .x((d) => getX(
            ((undefined!=d.variant)&&(d.variant=="trimester"))?
                 (
                    new Date((1900+d.year.getYear())+"-"+ 
                        ((parseInt(d.trimester)*3<10)?"0":"")
                        +
                        (
                            (parseInt(d.trimester)*3).toString()
                        )
                        +"-15"
                    )
                )
            :
                new Date(d.year)
        ))
        .y0((d) => getY(d.data))
        .y1(() => getY(yMinValue - 1))
        .curve(d3.curveMonotoneX)(dataRep.data);

    const capitalizeString = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1, name.length);
    }
    const determineCircleColor = (elementGroup) => {
        if (elementGroup == undefined)
            return color;//fallback
        var index = groupedDataKeys.indexOf(elementGroup);
        return lineColors[index];
    }

    const renderLegends = () => {
        return (
            <div className={classes.graphLegendsContainer}>
                {
                    groupedDataKeys.map((element, index) => {
                        return (
                            <div key={index} className={classes.graphLegend}>
                                <div className={classes.graphLegendVisualRepresentation} style={{ background: determineCircleColor(element) }}>
                                </div>
                                <div>
                                    <Typography variant="subtitle2">{capitalizeString(element)}</Typography>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /////////////////// INNER COMPONENT, just like nested classes on any OOP language //////////////////////////////////
    function DataDot(props) {
        const { item, index, requiresPercentage } = props;
        const [focused, setFocused] = React.useState(false);

        
        const handleFocus = (state) => {
            setFocused(state);
        }

        const getDisplayableText = (itemx)=>{
            var valueToDisplay = itemx.unscaledData? itemx.unscaledData : itemx.data;
            //console.log("ITEM X :",itemx);
            if (itemx.type != undefined && itemx.type == "percentage")
                return (valueToDisplay*100).toFixed(2)+"%";
            else
                return numberWithCommas(valueToDisplay);
            
        };

        
        return (
            <g key={index} onMouseOver={() => handleFocus(true)} onMouseOut={() => handleFocus(false)}>
                {console.log("Inside DataDot:::::",item,
                                ((undefined!=item.variant)&&(item.variant=="trimester"))?
                                (1900+item.year.getYear()).toString()+"-"+
                                (
                                    ( parseInt(item.trimester)*3 <10 )? "0":""                            
                                )+ (parseInt(item.trimester)*3).toString()+"-15"
                            :    
                            item.year)}
                <text
                    fill="#666"
                    x={getX(
                          new Date(
                                ((undefined!=item.variant)&&(item.variant=="trimester"))?
                                (1900+item.year.getYear()).toString()+"-"+
                                (
                                    ( parseInt(item.trimester)*3 <10 )? "0":""                            
                                )+ (parseInt(item.trimester)*3).toString()+"-15"
                            :    
                            item.year
                        )                        
                    )}
                    y={getY(item.data) - 20}
                    textAnchor="middle"
                    
                    className="textOnChartPoint"
                >
                    {focused ? 
                        (((undefined!=item.variant)&&(item.variant=="trimester"))?
                        "Trimestre "+item.trimester+":\n ":"")+
                        getDisplayableText(item) 
                        
                    : ""}
                    
                </text>
                <circle
                    cx={getX(
                        new Date(
                            ((undefined!=item.variant)&&(item.variant=="trimester"))?
                                 (
                                    new Date((1900+item.year.getYear())+"-"+ 
                                        ((parseInt(item.trimester)*3<10)?"0":"")
                                        +
                                        (
                                            (parseInt(item.trimester)*3).toString()
                                        )
                                        +"-15"
                                    )
                                )
                            :
                                new Date(item.year)
                
                        )
                    )}
                    cy={getY(item.data)}
                    r={6}
                    fill="#fff"
                    strokeWidth={2}
                    stroke="#fff"
                    style={{ transition: "ease-out .1s", cursor: "pointer" }}
                />
                <circle
                    cx={getX(
                        new Date(
                            ((undefined!=item.variant)&&(item.variant=="trimester"))?
                                 (
                                    new Date((1900+item.year.getYear())+"-"+ 
                                        ((parseInt(item.trimester)*3<10)?"0":"")
                                        +
                                        (
                                            (parseInt(item.trimester)*3).toString()
                                        )
                                        +"-15"
                                    )
                                )
                            :
                                new Date(item.year)
                
                        )
                    )}
                    cy={getY(item.data)}
                    r={focused ? 4 : 2}
                    fill={determineCircleColor(item.name)}
                    strokeWidth={focused ? 2 : 0}
                    stroke="#fff"
                    style={{ transition: "ease-out .1s", cursor: "pointer" }}
                />
            </g>
        )
    }
    /////////////////// END OF INNER COMPONENT //////////////////////////////////
    return (
        <div style={{ padding: requiresLegends? (dataRep.scaledBy? '25px 0px' : '15px 0px 25px 0px') : '5px 0px'}}>
            <svg style={{ background: isPreview ? '#ffffff' : '#ffffff' }}
                viewBox={`0 0 ${width} ${isPreview ? height : height + 20}`}
            >
                {(requiresLegends && dataRep.scaledBy )? <text textAnchor="left" x={dataRep.scaledBy.xOffset} y="-4" >{dataRep.scaledBy.label} </text> : null}
                <g ref={getYAxis} transform={`translate(${requiredSizeForYAxis},0)`} />
                <g
                    className="axis xAxis"
                    ref={getXAxis}
                    transform={`translate(0,${height - requiredSizeForXAxis})`}
                />
                {
                    groupedDataKeys.map((element, index) => {
                        return (
                            <path strokeWidth={1.4} fill="none" stroke={lineColors[index]} d={linePath(element)} />
                        )
                    })
                }
                {dataRep.data.map((item, index) => {
                    return (
                        <DataDot item={item} index={index} requiresPercentage={requiresPercentage} />
                    );
                })}
            </svg>
            {
                isPreview ? null : (requiresLegends ? renderLegends() : null)
            }
        </div>
    );
}