const headerstyle = (theme) => ({
    appBar: {
        backgroundColor: '#fefefe',
        boxShadow: 'none',
        height: '70px',
        borderBottom: '1px solid ' + theme.palette.secondary.lighter
        /*border: '1px solid '+theme.palette.secondary.lighter,
        borderLeft: 'none !important',
        borderRight: 'none !important',
        borderTop: 'none !important'*/
    },
    wrapper: {
        margin: '0 auto',
        maxWidth: '90%',
        width: '1160px',
        height: "70px"
    },
    navLinks: {
        fontSize: '1.75rem',
        marginRight: '12px'
    },
    navLink: {
        fontSize: '12px',
        marginRight: '12px',
        fontWeight: "bold"
    },
    toolBar: {
        padding: '0 !important',
        minHeight: '70px !important',//overriding Mui toolbar default styles
        height: '70px !important' //overriding Mui toolbar default styles
    },
    base: {
        height: 'auto',
        margin: '0 20px'
    },
    leftContent: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: "10px",
    },
    rightContent: {
        flex: '1', //el flex 1 acá es para que agarre todo lo que resta del flex container padre, y luego lo posicionamos hasta el final. Logrando asi un posicionamiento a la derecha
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: theme.palette.primary.main
    },
    rightContentElementsFirst: {

        height: "30.5px",
        verticalAlign: 'middle',
        display: "flex",
        alignContent: 'flex-end',
        alignItems: 'center'
    },
    navBarButton: {
        height: '20px',
        width: '20px',
        border: '1px solid black',
        borderRadius: '2px',
        margin: '0px 10px',
        cursor: 'pointer',
        color: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.hover.backgroundColor
        }
    },
    headerContainer: {
        width: '150px',
        [theme.breakpoints.down('sm')]: {
            width: '100px !important'
        },
    },
    headerLogo: {
        width: 'auto',
        height: '55px'
    },
    searchBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        margin: '15px 0',

    },
    searchBox: {
        width: '640px',
        [theme.breakpoints.down('sm')]: {
            width: '300px !important'
        },
    },
    navigationMenuRootCategory: {
        fontWeight: 700
    },
    navigationMenuItemLink: {
        textDecoration: 'none',
        color: 'inherit'
    },
    navBarIcon: {
        marginRight: '10px'
    },
    popoverPaper: {
        width: '100%',
        maxWidth: 'none !important',
        left: '0 !important',
        boxShadow: 'none !important',
        marginTop:'10px',
        top: '60px !important'
    },
    popoverPaperContent:{
        margin: '0 auto',
        maxWidth: '90%',
        width: '1160px',
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        height:'500px',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
			overflowY:'hidden',
            height:'auto'
		},

    },
    popoverPaperContentColumn:{
        fontSize:'1.75rem',
        [theme.breakpoints.up('sm')]: {
			marginRight:'30px'
		},
    },
    popoverPaperContentColumnTitle:{
        fontWeight:'700',
        margin:'10px 10px 20px',
    },
    popoverPaperContentColumnSubtitle:{
        margin:'10px 10px 20px 30px',
        [theme.breakpoints.up('sm')]: {
			margin:'10px 10px 20px 10px !important'
		},
    },
    mobileHidden:{
        display:'none',
        [theme.breakpoints.up('sm')]: {
            display:'block !important'
        },
    }
})

export default headerstyle;
