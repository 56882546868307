import axios from "axios";
import React from "react";
import ApiURLs from '../config/ApiURLs.js';

export const AppContext = React.createContext({isLoading:true, institutions:[]});

export const AppProvider = (props)=>{
    const [state, setState] = React.useState({isLoading:true, institutions:[]});
    //const [websiteAssets, setWebsiteAssets] = React.useState({isLoading:true, data:[]});
    React.useEffect(()=>{
        axios.get(ApiURLs.getURL(ApiURLs.baseURL+"website/institutions","json")).then((data)=>{
            //setState({isLoading:false,institutions:data.data.data});
            setState({isLoading:false,institutions:data.data});
        });
        /*axios.get(ApiURLs.baseURL+"website/assets").then((data)=>{
            setWebsiteAssets({isLoading:false,data:data.data});
        });*/

    },[]); 
    
    return (
        <AppContext.Provider value={{"state":state /*,"websiteData":websiteAssets*/}} state={state} >
            {props.children}
        </AppContext.Provider>
    )
};

export default AppContext;