const primaryScreenstyle = (theme) => ({
    title: {
        textAlign: 'center',
        //fontSize: '2.4rem', now its beging inherited from customTheme on index.js
        marginBottom:'0',
        // [theme.breakpoints.up(768)]: { //now its beging inherited from customTheme on index.js
        //     fontSize: '3rem !important'
        // },
    },
    titleFlujograma: {
        textAlign: 'center',
        //fontSize: '2.4rem', now its beging inherited from customTheme on index.js
        marginBottom:'0',
        // [theme.breakpoints.up(768)]: { //now its beging inherited from customTheme on index.js
        //     fontSize: '3rem !important'
        // },
    },

    mediaCard:{
        height:'490px'
    },
    subtitleContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center'
    },
    subtitle: {
        marginTop:'5px',
        textAlign: 'center',
        color: '#81878C',
        fontWeight:'normal',
        maxWidth:'600px'
    },
    searchBoxContainer:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        width:'100%',
        margin:'15px 0 40px',
    },
    searchBox:{
        width:'640px',
        [theme.breakpoints.down('sm')]: {
            width: '300px !important'
        },
    },
    margin: {
        backgroundColor:'white',
        borderRadius:'4px',
        border:'1px solid '+theme.palette.secondary.light
    },
})

export default primaryScreenstyle;