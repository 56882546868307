import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "./BaseCard.js";
import styles from "../assets/jss/baseCardstyle.js";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom"
const useStyles = makeStyles(styles);
export default function SlideshowCard(props) {
    const { slideElements } = props;
    const classes = useStyles();
    return (
        <BaseCard >
            <Link to={"/viewChart/"+slideElements[0].chartId} style={{ textDecoration: 'none' }}>
                {/*<div className={classes.headerContainer} style={{ borderBottom: '0px' }}>
                    <Typography variant="h2" className={classes.headerWithSlideShowControls}> </Typography>
                    {/*<div className={classes.slideshowLeftIcon}><i className='fa fa-caret-left '></i></div>
                    <div className={classes.slideshowRightIcon}><i className='fa fa-caret-right '></i></div>}
                </div>
                */}
                <div className={classes.slideshowContainer}>
                    {
                        <div>
                            <div> 
                                {
                                    slideElements[0].main
                                }
                            </div>
                            <div className={classes.slideshowElementDescriptionContainer}>
                                <Typography variant="h3" className={classes.mediaTitle}>{slideElements[0].title}</Typography>
                                <Typography variant="caption" className={classes.mediaSubtitle}>{slideElements[0].subtitle}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </Link>
        </BaseCard>
    );
}