import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import FlujogramaBaseScreen from "./FlujogramaBaseScreen.js";
import { Typography } from "@material-ui/core";
import styles from "../assets/jss/flujogramaScreenstyle.js";
import { useLocation,  useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Select from '@material-ui/core/Select';
import BaseScreen from "./BaseScreen.js";
//import FeedItem from "../components/FeedItem.js";
import YoutubeEmbed from "../components/YoutubeEmbed";
//import imageFlujograma from "../assets/img/history/FLUJOGRAMA2020.svg";

const useStyles = makeStyles(styles);

export default function FlujogramaScreen(props) {
    let { year } = useParams();
    //const rootRef = React.useRef(null);
    const location = useLocation();
    const classes = useStyles();
    //const [pageObject, setPageObject] = React.useState({ isLoading: true, data: { title: '...' } });
    let imageFlujograma = "/history/FLUJOGRAMA" + year.toString() + ".svg";
    const fullScreenHandle = useFullScreenHandle();
    useEffect(() => {

    }
        , [location]);
    const handleChange = (e) => {
        console.log("changed", e);
        props.history.push("/flujograma/" + e.target.value);
    };
    if ((year < 2014) || (year > 2020)) props.history.push("/notfound");
    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6">
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.divisoryClass}>
                    <Typography variant="h1" className={classes.title}>
                        Flujograma
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <div className={classes.graphOptionsMenuContainer}>
                        <div className={classes.graphOptionsMenuContainerTitle}>
                            <Typography variant="h2" className={classes.emphasizedHeader}>Año</Typography>
                            <Select
                                native
                                value={year}
                                onChange={handleChange}

                                inputProps={{
                                    name: 'anio',
                                    id: 'outlined-year-native-simple',
                                }}
                                disableUnderline
                                style={{ paddingBottom: '5px', paddingLeft: '10px' }}
                            >
                                <option value={2014}>2014</option>
                                <option value={2015}>2015</option>
                                <option value={2016}>2016</option>
                                <option value={2017}>2017</option>
                                <option value={2018}>2018</option>
                                <option value={2019}>2019</option>
                                <option value={2020}>2020</option>
                            </Select>
                        </div>
                        <div className={classes.graphOptionsMenuButton} onClick={fullScreenHandle.enter}>
                            <div className={classes.graphOptionsMenuIcon}>
                                <i class="fas fa-expand"></i>
                            </div>
                            <Typography variant="subtitle2">Expandir</Typography>
                        </div>
                    </div>
                    <div className={classes.flujogramaImageContainer}>
                        <FullScreen handle={fullScreenHandle}>
                            <img src={imageFlujograma} className={classes.headerLogo} alt='Flujograma de la Justicia Criminal'/>
                        </FullScreen>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.rightContentContainer}>
                        <div className={classes.rightSideTextContainer}> 

                                <YoutubeEmbed embedId="pip5vlZcAoU" />
                        </div>
                </Grid>
            </Grid>

        </BaseScreen>
    )
}
