import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FadeInModal from "../components/FadeInModal";
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import YoutubeEmbed from "../components/YoutubeEmbed";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		borderRadius: "0px",
		alignItems:"flex-start"
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: "5px"
	},
	content: {
		flex: '1 0 auto',
		padding:"0px",
		paddingLeft:"10px"
	},
	cover: {
		width: "120px !important",
		height: "70px",
		minWidth: "120px"
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(1),

	},
	playIcon: {
		height: 38,
		width: 38,
	},
	itemTitle:{
		fontSize:"1.3rem",
		fontWeight: "bold",
		paddingTop:"10px"
	}
}));

export default function FeedItem(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [modalState, setModalState] = React.useState(false);
	const [modalVideoId, setModalVideoId] = React.useState(false);
	const closeModal = () => { //function to pass through props to FadeInModal
		setModalState(false);
	};
	if ((props.variant=="videolist")&&
		(
			(props.dataObject.video_id==undefined)||(props.dataObject.video_id.trim()=="")
		)) return "";
	return (

		<Card className={classes.root}>
			<ButtonBase className={props.classes} onClick={(event) => { setModalVideoId(props.dataObject.video_id); setModalState(true) }} data-videoid={(props.variant=="videolist")?props.dataObject.video:""}>
				{ (props.variant!="videolist")?
					<CardMedia
						className={classes.cover}
						image={props.image}
						title={props.title}
					/>:
					<CardMedia
						className={classes.cover}
						image={"https://img.youtube.com/vi/"+props.dataObject.video_id+"/0.jpg"}
						title={props.title}
					/>
				}
				<div className={classes.details}>

					<CardContent className={classes.content}>
						<div class={classes.itemTitle}>
							{props.title}
						</div>
						<Typography variant="subtitle1" color="textSecondary">
							{(props.variant=="videolist")?props.dataObject.description:props.content}
						</Typography>
					</CardContent>
					{/*<div className={classes.controls}>
						{props.content}
					</div>*/}
				</div>


			</ButtonBase>

			<FadeInModal open={modalState} handleClose={closeModal} isYoutubeEmbed>
				<div style={{textAlign:"center",width:"100%",height:"100%"}}>
				<div style={{width:"100%",height:"100%", maxWidth:"720px",maxHeight:"405px",margin:"auto"}}>
					<YoutubeEmbed embedId={modalVideoId} />
				</div>
				</div>
			</FadeInModal>
		</Card>
	);
}
