import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
//import logo4 from "assets/img/lasalleLogo.svg"; logo to be displayed
//import exampleLogo from "../assets/img/cienLogo.png";
import exampleLogo from "../assets/img/logo.svg";
import styles from "../assets/jss/headerstyle.js";
import { Link } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import clsx from 'clsx';
import SimpleHeaderMenu from "./SimpleHeaderMenu";
import AppContext from "../state/AppContext.js";
const useStyles = makeStyles(styles);
export default function PrimaryHeader(props) {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    
    const retrieveInstitutions = ()=>{
        if(!appContext.state.isLoading)
            return appContext.state.institutions;
        else return [];
    }

    return (
        <AppBar position='static' className={classes.appBar}  >
            <div className={classes.wrapper}>
                <Toolbar className={classes.toolBar} disableGutters>
                    <div className={classes.leftContent}>
                        <Link to="/index" className={classes.headerContainer} >
                            <img src={exampleLogo} className={classes.headerLogo} alt="Flujograma de la Justicia Criminal" />
                        </Link>
                    </div>
                    <div className={classes.rightContent}>
                        {/* <h3 className={classes.navLinks}>Índice de datos</h3> */}
                        {/* <Typography variant="h3" className={classes.navLinks}>Índice de datos</Typography>
                        <MenuIcon className={classes.navBarButton} /> */}
                        <div className={classes.rightContentElementsFirst} >
                            
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://www.instagram.com/cien.guatemala/" }} target="_blank"><i class="fab fa-instagram fa-2x"/></Link>
                                
                            </div>                            
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://www.facebook.com/cien.guatemala/" }} target="_blank"><i class="fab fa-facebook fa-2x"/></Link>
                                
                            </div>
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://twitter.com/ciengt" }} target="_blank"><i class="fab fa-twitter fa-2x"/></Link>
                            </div>
                        </div>
                        <div className={classes.rightContentElementsFirst}>
                            <Link to='/'><div className={classes.navLink}><i class="fas fa-home"/></div></Link>
                            <SimpleHeaderMenu options={[{id:'/flujograma/'+(new Date().getFullYear()-1), name:'Anual'}, {id:'/flujograma-trimestre/'+(new Date().getFullYear())+"/1", name:'Trimestral'}]} rootComponent={<Link onClick={(event)=>event.preventDefault()}> <div className={clsx(classes.navLink, classes.mobileHidden)}>Flujograma</div></Link>}></SimpleHeaderMenu>
                            <Link to='/global'><div className={clsx(classes.navLink, classes.mobileHidden)}>Eficiencia Global</div></Link>
                            {/* <Link to='/institutions'><div className={clsx(classes.navLink, classes.mobileHidden)}>Eficiencia por institución</div></Link> */}
                            <SimpleHeaderMenu options={retrieveInstitutions()} prefix="/institution/" rootComponent={<Link onClick={(event)=>event.preventDefault()}><div className={clsx(classes.navLink, classes.mobileHidden)}>Eficiencia por Institución</div></Link>}></SimpleHeaderMenu>
                            <Link to='/methodology'><div className={clsx(classes.navLink, classes.mobileHidden)}>Metodología</div></Link>
                            <HeaderMenu />

                            {/*<h3 className={classes.navLinks}>Índice de datos</h3>
                             */}
                        </div>

                    </div>
                </Toolbar>
            </div>
        </AppBar>
    );
}