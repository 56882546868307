import md5 from "md5";

const ApiURLs = {
	baseURL: 'https://app.justiciacriminal.gt/v1/',
	//baseURL: 'https://flujograma.app.local/v1/'
	getURL: function(url,type){
		let tgs=document.getElementsByTagName('head');
		let __wp_jcgt='latest';
		window.__wp_jcgt=__wp_jcgt;
		if (tgs.length>0) {
			if (tgs[0].hasAttribute("v"))
				__wp_jcgt=tgs[0].getAttribute('v');
		}
		return ("https://data.justiciacriminal.gt/"+__wp_jcgt+"/"+md5(url)+"."+type);
	}
};

export default ApiURLs;
