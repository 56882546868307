const linearChartstyle = (theme)=>({
    chart:{
        width:'100%',
        maxHeight:'460px',
    },
    graphLegendVisualRepresentation:{
        height:'10px',
        width:'10px',
        borderRadius:'50%',
        [theme.breakpoints.up('sm')]: { 
            height:'15px',
            width:'15px',
        },
    },
    graphLegend:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        margin:'10px'
    },
    graphLegendText:{
        
    },
    graphLegendsContainer:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'center'
    }

});
export default linearChartstyle;