import React from "react";
import styles from "../assets/jss/headerstyle.js";
import {/* withStyles, */makeStyles } from '@material-ui/core/styles';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AppContext from "../state/AppContext.js";
import { Popover, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
/*const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
*/
/*
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
*/

const useStyles = makeStyles(styles);


/*const websiteMenuLinks =
    [
        {
            "id": 1,
            "label": "Términos y Condiciones",
            "linkTo": "/terms-and-conditions"
        },
        {
            "id": 2,
            "label": "Preguntas frecuentes",
            "linkTo": "/faq"
        },
        {
            "id": 3,
            "label": "Resolución de problemas",
            "linkTo": "/troubleshooting"
        }
    ]
    ;*/

export default function HeaderMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const appContext = React.useContext(AppContext);
    const theme = useTheme();
    const isNonMobile = useMediaQuery(theme.breakpoints.up('sm'));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const conditionalMenu = () => {
        if (isNonMobile)
            return null; //because these options will appear as navLinks :D so theres no need to render them here
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/global'}>
                        <Typography variant="caption" >
                            Eficiencia Global
                        </Typography>
                    </Link>
                </div>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/institutions'}>
                        <Typography variant="caption" >
                            Eficiencia por Institución
                        </Typography>
                    </Link> </div>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/methodology'}>
                        <Typography variant="caption" >
                            Metodología
                        </Typography>
                    </Link>
                </div>
            </div>
        )
    };

    const renderDynamicPages = () => {
        ///TODO - Backend route to return a list of all the dynamic pages (Just like the one that returns all the institutions), so in this method we would only iterate over that array instead of just displaying static data. 
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle}> Navegación </div>
                {/* {
                    appContext.institutions.map((element, index) => {
                        return (
                            <div className={classes.popoverPaperContentColumnSubtitle} key={index}>
                                <Link to={'/institution/' + element.id}>
                                    <Typography variant="caption" >
                                        {element.name}
                                    </Typography>
                                </Link>
                            </div>
                        )
                    })
                } */}
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/terms-and-conditions'}>
                        <Typography variant="caption" >
                            Términos y Condiciones
                        </Typography>
                    </Link>
                </div>
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/faq'}>
                        <Typography variant="caption" >
                            Preguntas frecuentes
                        </Typography>
                    </Link>
                </div>
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/troubleshooting'}>
                        <Typography variant="caption" >
                            Resolución de problemas
                        </Typography>
                    </Link>
                </div>
            </div>
        )
    };

    const renderFlujogramaMenu = ()=>{
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle}> Flujograma </div>
                {/* {
                    appContext.institutions.map((element, index) => {
                        return (
                            <div className={classes.popoverPaperContentColumnSubtitle} key={index}>
                                <Link to={'/institution/' + element.id}>
                                    <Typography variant="caption" >
                                        {element.name}
                                    </Typography>
                                </Link>
                            </div>
                        )
                    })
                } */}
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/flujograma/'+(new Date().getFullYear()-1)}>
                        <Typography variant="caption" >
                            Anual
                        </Typography>
                    </Link>
                </div> 
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/flujograma-trimestre/'+(new Date().getFullYear())+"/1"}>
                        <Typography variant="caption" >
                            Trimestral
                        </Typography>
                    </Link>
                </div>
                {/* <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/terms-and-conditions'}>
                        <Typography variant="caption" >
                            Semestral
                        </Typography>
                    </Link>
                </div> */}
            </div>
        )
    }

    const renderInstitutions = () => {
    
        if (!appContext.state.isLoading) {
            return (
                <div className={classes.popoverPaperContentColumn}>
                    <div className={classes.popoverPaperContentColumnTitle}> Instituciones </div>
                    {
                        appContext.state.institutions.map((element, index) => {
                            return (
                                <div className={classes.popoverPaperContentColumnSubtitle} key={index} onClick={handleClose}>
                                    <Link to={'/institution/' + element.id}>
                                        <Typography variant="caption" >
                                            {element.name}
                                        </Typography>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }
    }

    return (
        <div>
            <MenuIcon className={classes.navBarButton} style={{ marginRight: 'none !important' }} onClick={handleClick} />
            <Popover
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.popoverPaper }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            //PaperProps={{style:{width:'100%', maxWidth:'none !important', left:'0px !important'}}}
            >
                <div className={classes.popoverPaperContent}>
                    {renderFlujogramaMenu()}
                    {renderInstitutions()}
                    {conditionalMenu()}
                    {/* <div className={classes.popoverPaperContentColumn}>
                        <div className={classes.popoverPaperContentColumnTitle}> Navegación </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Preguntas frecuentes </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Términos y Condiciones </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Resolución de problemas </div>
                    </div> */}
                    {renderDynamicPages()}
                    {/* <div className={classes.popoverPaperContentColumn}>
                        <div className={classes.popoverPaperContentColumnTitle}> Quienes somos </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> CIEN </div>
                    </div> */}
                    {/* <div className={classes.popoverPaperContentColumn}>
                        <div className={classes.popoverPaperContentColumnTitle}> Instituciones </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Organismo Judicial </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Ministerio Público </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Instituto Nacional de Ciencias Forenses </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Instituto de la Defensoría Pública Penal </div>
                        <div className={classes.popoverPaperContentColumnSubtitle}> Sistema Penitenciario </div>
                    </div> */}
                    
                </div>
            </Popover>
        </div>
    );
}